const DocumentsEndpoints = {
  index: "documents/",
  new: "documents/",
  delete: "documents/delete/",
  show: "documents/preview/",
  showPostFiles: "document/post/show/",
  addToPost: "document/post/new/",
  removeFileFromPost: "document/post/delete/",
  connectFileToPost: "document/post/connect/",
  sort: "document/post/sort"
};

export default DocumentsEndpoints;
