import { useTranslation } from "react-i18next";
import ApexChart from "../../Charts/ApexChart";
import { useEffect, useState } from "react";
import crudApi from "@serie3/common/api/crudApi";
import DashboardEndpoints from "@serie3/mymediaplanner/Modules/Dashboard/endpoints";

const Stats = ({ stats }) => {
  const [dashboardData, setDashboardData] = useState(null);
  const { t } = useTranslation();
  const [data, setData] = useState(null);

  useEffect(() => {
    const getDashboardData = async () => {
      let response = await crudApi.justGet(DashboardEndpoints.stats);
      if (response.ok) {
        setDashboardData(response.data);
        const categories = [];
        const views = [];
        const likes = [];
        const comments = [];


        if(response.data.posts.length > 0){
        response.data.posts.forEach((schedule) => {
          categories.push(schedule.post.title);

          if (schedule.post.stats && schedule.post.stats.length > 0) {
            views.push(schedule.post.stats[0].views);
            likes.push(schedule.post.stats[0].likes);
            comments.push(schedule.post.stats[0].comments);
          }
        });

        const total = "Views: " + response.data.totalStats.views + " Likes: " + response.data.totalStats.likes + " Comments: " + response.data.totalStats.comments;
        const data = {
          chartCategories: categories,
          totalStats: total,
          chartSeries: [
            { name: "Views", data: views },
            { name: "Likes", data: likes },
            { name: "Comments", data: comments },
          ],
        };

        setData(data);
      }else{
        setData(null); 
      }
      }
    };

    getDashboardData();
  }, []);

  return data ? <ApexChart title={t("Backend.Dashboard.Stats")} data={data} />: t("Backend.Dashboard.NoStatsData");
};

export default Stats;
