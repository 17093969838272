import "@serie3/common/Assets/css/dropzone.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import useToken from "./security/useToken";
import AuthContext from "@serie3/common/auth/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FrontendRouter from "./components/frontend/FrontendRouter";
import BackendRouter from "./components/backend/BackendRouter";
import "./assets/scss/theme.scss";
import "./assets/scss/app.scss";
import "./assets/scss/icons.scss";
import "./assets/css/Telepromter.css";
import BlogList from "./components/blog/BlogList";
import Blogpost from "./components/blog/Blogpost";


function App() {
  const { token, setToken } = useToken();

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <AuthContext.Provider value={{ token, setToken }}>
        <Router>
          <Routes>
            <Route path="/blog" element={<BlogList />} />
            <Route path="/blog/:splat" element={<Blogpost />} />
            {!token && (
              <Route
                path="/*"
                element={<FrontendRouter setToken={setToken} token={token} />}
              />
            )}

            <Route path="/*" element={<BackendRouter setToken={setToken} token={token} />} />
          </Routes>
        </Router>
      </AuthContext.Provider>
    </>
  );
}

export default App;
