import React, { useState } from "react";
import Chart from "react-apexcharts";
import { Card } from "react-bootstrap";

const DonutChart = ({
  chartLabels = [],
  series = [],
  title = "",
  type = "donut",
}) => {
  const [options] = useState({
    legend: {
      position: "bottom", // This places the legend underneath the chart
    },
    theme: {
      monochrome: {
        enabled: true,
      },
    },
    labels: chartLabels,
    responsive: [], // Add configurations if necessary
  });

  return (
    <>
      {chartLabels.length > 0 && series.length > 0 && (
        <Card className="d-flex justify-content-center align-items-center">
          <Card.Body>
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h5 className="card-title mb-4">{title}</h5>
              </div>
              <div className="flex-shrink-0"></div>
            </div>
            <Chart options={options} series={series} type={type} width="380" />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default DonutChart;
