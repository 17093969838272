import { useEffect, useState } from "react";
import generateRandomString from "@serie3/mymediaplanner/Domain/generateRandom";
import { Link, useSearchParams } from "react-router-dom";
import getLinkedinSettings from "@serie3/mymediaplanner/Config/Social/linkedinSettings";
import crudApi from "@serie3/common/api/crudApi";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import { Button, Row, Col, Card } from "react-bootstrap";
import ToastMessages from "../../ToastMessages";

const Callbacks = () => {
  const linkedinSettings = getLinkedinSettings();
  const [authUrl, setAuthUrl] = useState("");
  const [searchParams] = useSearchParams();
  const [linkedinConnected, setLinkedinConnected] = useState(false);


  const disconnect = async () => {
    let response = await crudApi.justGet(SocialEndpoints.linkedin.disconnect);
    response.ok ? ToastMessages.SuccessfulSave() : ToastMessages.Error();
  };

  const createLinkedinAuthLink = (statusCode = "") => {
    return (
      linkedinSettings.linkeindAuthApiUrl +
      "?response_type=" +
      linkedinSettings.response_type +
      "&state=" +
      statusCode +
      "&client_id=" +
      linkedinSettings.client_id +
      "&redirect_uri=" +
      linkedinSettings.redirect_uri +
      "&scope=" +
      linkedinSettings.scope
    );
  };

  useEffect(() => {
    const getConnectedPlatforms = async () => {
      let linkedinConnected = await crudApi.justGet(SocialEndpoints.linkedin.isConnected);
      if (linkedinConnected.ok) {
        setLinkedinConnected(linkedinConnected.data.connected);
      }
    };

    let statusCode = localStorage.getItem("code");
    let code = searchParams.get("code");
    let linkedinState = searchParams.get("state");

    if (code && linkedinState) {
        let data = {
          code: code,
        };

        crudApi.createNew(SocialEndpoints.linkedin.connect, data);

    } else {
      if (!statusCode) {
        statusCode = generateRandomString();
        localStorage.setItem("code", statusCode);
      }
      let authUrl = createLinkedinAuthLink(statusCode);
      setAuthUrl(authUrl);
    }
    

    getConnectedPlatforms();
  }, []);

  const ConnectButton = ({ platformName }) => {

    let authUrl = '';
    let statusCode = generateRandomString();
    localStorage.setItem("code", statusCode);
    switch (platformName) {
      case 'LinkedIn':
        authUrl = createLinkedinAuthLink(statusCode);
        break;
      case 'Facebook':
        authUrl = '/auth/facebook';
        break;
      case 'Twitter':
        authUrl = '/auth/twitter';
        break;
      default:
        authUrl = '/auth/default'; // Fallback URL if no platform matches
        break;
    }
    
    return (
      <Link className="btn btn-primary" to={authUrl}>
        Connect to {platformName}
      </Link>
    );
  };

  const DisconnectButton = ({ platformName }) => {
    return (
      <Button
        className="btn btn-danger"
        onClick={() => {
          disconnect();
        }}
      >
        Disconnect from {platformName}
      </Button>
    );
  };

  return (
    <Row>
      <Col>
        <h4>Available platforms</h4>
        <Card>
          <Card.Body>
            {linkedinConnected ? <DisconnectButton platformName={"LinkedIn"}/> : <ConnectButton platformName={"LinkedIn"}/>}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Callbacks;
