import { Row, Col, Card, CardBody } from "react-bootstrap";
import { useEffect, useState } from "react";
import crudApi from "@serie3/common/api/crudApi";
import DashboardEndpoints from "@serie3/mymediaplanner/Modules/Dashboard/endpoints";
import { useTranslation } from "react-i18next";
import { formatDate } from "@serie3/common/Shared/timeingStuff";
import PlanOverview from "./components/Dashboard/Widgets/PlanOverview";
import PostTypes from "./components/Dashboard/Widgets/PostTypes";
import Stats from "./components/Dashboard/Widgets/Stats";
import Accountability from "./components/Dashboard/Widgets/Accountability";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [types, setTypes] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    const getDashboardData = async () => {
      let response = await crudApi.justGet(DashboardEndpoints.overview);
      if (response.ok) {
        setDashboardData(response.data);
      }
    };

    getDashboardData();
  }, []);

  return dashboardData ? (
    <>
      <Row>
        <Col md={3}>
          <Card>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-primary-subtle text-primary rounded-circle font-size-18">
                      <i className="uil uil-list-ul"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1 text-truncate text-muted">
                    {t("Backend.Dashboard.TotalPosts")}
                  </p>
                  <h5 className="font-size-16 mb-0">
                    {dashboardData.posts.length}
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-primary-subtle text-primary rounded-circle font-size-18">
                      <i className="uil uil-schedule"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1 text-truncate text-muted">
                    {t("Backend.Dashboard.Schedules")}
                  </p>
                  <h5 className="font-size-16 mb-0">
                    {dashboardData.schedules.length}
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-primary-subtle text-primary rounded-circle font-size-18">
                      <i className="uil uil-bug"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1 text-truncate text-muted">
                    {t("Backend.Dashboard.Errors")}
                  </p>
                  <h5 className="font-size-16 mb-0">
                    {dashboardData.error.length}
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          {dashboardData.tokens &&
            dashboardData.tokens.localToken &&
            dashboardData.tokens.localToken.length > 0 &&
            dashboardData.tokens.localToken.map((tokendata, index) => {
              return (
                <Card>
                  <CardBody>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                          <div className="avatar-title bg-primary-subtle text-primary rounded-circle font-size-18">
                            <i className="uil uil-hourglass"></i>
                          </div>
                        </div>
                      </div>
                      <div className="flex-grow-1 overflow-hidden">
                        <p className="mb-1 text-truncate text-muted">
                          {tokendata.plattform_name}{" "}
                          {t("Backend.Dashboard.TokenExpires")}
                        </p>
                        <h5 className="font-size-16 mb-0">
                          {formatDate(tokendata.expires)}
                        </h5>
                      </div>
                      <h6 className="font-size-xs text-uppercase"></h6>
                      <h4 className="mt-4 font-weight-bold mb-2 d-flex align-items-center"></h4>
                    </div>
                  </CardBody>
                </Card>
              );
            })}
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <Stats />
        </Col>
        <Col
          className="d-flex flex-column justify-content-between"
          style={{ height: "100%" }}
        >
          <div className="mb-3 flex-grow-1">
            <PlanOverview />
          </div>
          <div>
            <Accountability />
          </div>
        </Col>
      </Row>
      <Row>
        <PostTypes types={types} />
      </Row>
    </>
  ) : (
    <Row>
      <Col>Dashboard can not be loaded</Col>
    </Row>
  );
};

export default Dashboard;
