import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { Navbar } from "react-bootstrap";

const Header = ({ showSidebar, setShowSidebar }) => {
  const { t } = useTranslation();
  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <Navbar bg="light" expand="lg" className="flex-column">
            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn ms-1"
              id="vertical-menu-btn"
              onClick={() => setShowSidebar(!showSidebar)}
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>
          </Navbar>
        </div>
      </div>
    </header>
  );
};

export default Header;
