import { Card, Row, Col, CardBody } from "react-bootstrap";

const AdminDashboard = () => {
  return (
    <Row>
      <Col>
        <Card>
          <CardBody>asdfasdf</CardBody>
        </Card>
      </Col>
      <Col></Col>
    </Row>
  );
};

export default AdminDashboard;
