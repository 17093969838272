import { Container, Navbar } from "react-bootstrap";
import Brand from "./Brand";
import i18n from "../../i18n.js";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

const HeaderNavbar = ({ currentLanguage, setCurrentLanguage }) => {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(i18n.language);
  };

  return (
    <Navbar style={{ zIndex: "100" }}>
      <Container>
        <Brand />
        <Link to={"/blog/impressum"}>
          <Trans i18nKey="Footer.Imprintlink">Imprint</Trans>
        </Link>
        <Link to={"/blog/datenschutz"}>
          <Trans i18nKey="Footer.Privacylink">Privacy</Trans>
        </Link>
        {currentLanguage === "de" ? (
          <span
            style={{ fontSize: "1.2rem", cursor: "pointer" }}
            onClick={() => {
              changeLanguage("en");
            }}
          >
            &#x1F1EC;&#x1F1E7;
          </span>
        ) : (
          <span
            style={{ fontSize: "1.2rem", cursor: "pointer" }}
            onClick={() => {
              changeLanguage("de");
            }}
          >
            &#x1F1E9;&#x1F1EA;
          </span>
        )}
      </Container>
    </Navbar>
  );
};

export default HeaderNavbar;
