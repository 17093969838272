import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  Dropdown,
  DropdownDivider,
} from "react-bootstrap";
import EllipsisVerticalToggle from "../../../shared/Dropdown/EllipsisVerticalToggle";
import { faMicrochip } from "@fortawesome/free-solid-svg-icons";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import crudApi from "@serie3/common/api/crudApi";
import EmojiPicker from "../../../shared/EmojiPicker";

const AIDropdownMenu = ({
  charCount = 0,
  formik,
  editData,
  setShowLoadingModal,
  cursorPosition,
  setCursorPosition,
  setTextCheckResult,
  setShowTextChecker
}) => {
  const { t } = useTranslation();


  const handleAddEmoji = (emoji) => {
    const text = formik.values.text || "";
    const newText =
      text.substring(0, cursorPosition) +
      emoji.native +
      text.substring(cursorPosition);

    formik.setFieldValue("text", newText);
    setCursorPosition(cursorPosition + emoji.native.length);
  };

  const finishPost = (response) => {
    formik.setFieldValue("text", response.data);
  };

  const checkText = (response) => {
    setTextCheckResult(response.data);
    setShowTextChecker(true);
  };

  const aiCall = async (url, postID, callBack) => {
    setShowLoadingModal(true);
    const response = await crudApi.justGet(url + postID);
    if (response.ok) {
      callBack(response);
      setShowLoadingModal(false);
    }
  };


  return (
    <>
      <Row>
        <Col className="d-flex align-items-center ">
          <strong>
            {t("Backend.Post.Text")} ({charCount})
          </strong>
        </Col>
        <Col className="text-end">
          <EmojiPicker handleAddEmoji={handleAddEmoji} />

          <Dropdown style={{ float: "right" }}>
            <EllipsisVerticalToggle icon={faMicrochip} />
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  aiCall(
                    SocialEndpoints.posts.ai.finishPost,
                    editData.id,
                    finishPost
                  );
                }}
              >
                <i className="pli-pen-5 fs-5 me-2" />
                {t("Backend.Post.FinishPost")}
              </Dropdown.Item>
              <DropdownDivider />
              <Dropdown.Item
                onClick={() => {
                  aiCall(
                    SocialEndpoints.posts.ai.createEducationalBlogpost,
                    editData.id,
                    finishPost
                  );
                }}
              >
                <i className="pli-pen-5 fs-5 me-2" />
                {t("Backend.Post.CreateEducationalBlogpost")}
              </Dropdown.Item>
              <DropdownDivider />
              <Dropdown.Item
                onClick={() => {
                  aiCall(
                    SocialEndpoints.posts.ai.createPostFromBulletpoints,
                    editData.id,
                    finishPost
                  );
                }}
              >
                <i className="pli-pen-5 fs-5 me-2" />
                {t("Backend.Post.CreatePostFromBulletpoints")}
              </Dropdown.Item>
              <DropdownDivider />
              <Dropdown.Item
                onClick={() => {
                  aiCall(
                    SocialEndpoints.posts.ai.checkText,
                    editData.id,
                    checkText
                  );
                }}
              >
                <i className="pli-pen-5 fs-5 me-2" />
                {t("Backend.Post.CheckText")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    </>
  );
};

export default AIDropdownMenu;
