const firebaseConfig = {
  apiKey: "AIzaSyBm_JILkPIw01lC_iXUVr8NpGb_18os1nk",
  authDomain: "mymediaplanner-live.firebaseapp.com",
  projectId: "mymediaplanner-live",
  storageBucket: "mymediaplanner-live.appspot.com",
  messagingSenderId: "69325004491",
  appId: "1:69325004491:web:e1804445ba8f0e75ab854e",
  measurementId: "G-MQ44J81DY1"
};

export default firebaseConfig