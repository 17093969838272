import { useState } from "react";
import Chart from "react-apexcharts";
import { Card } from "react-bootstrap";

const ApexChart = ({ title = "", data = null }) => {
  const [chartOptions, setChartOptions] = useState({
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: data.chartCategories, // X-axis labels for posts
    },
    yaxis: {
      title: {
        text: "Counts", // Y-axis label
      },
    },
    legend: {
      position: "top",
    },
    dataLabels: {
      enabled: true, // Disable data labels for a cleaner look
    },
    title: {
      text: data.totalStats,
      align: "center",
    },
  });

  return (
    data &&
    data.chartSeries.length > 0 &&
    data.chartCategories.length > 0 && (
      <Card>
        <Card.Body>
          <div className="d-flex align-items-start">
            <div className="flex-grow-1">
              <h5 className="card-title mb-4">{title}</h5>
            </div>
            <div className="flex-shrink-0"></div>
          </div>
          <Chart
            options={chartOptions}
            series={data.chartSeries}
            type="bar"
            height="400"
          />
        </Card.Body>
      </Card>
    )
  );
};

export default ApexChart;
