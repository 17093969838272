import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ImagesPreview from "./ImagesPreview";
import PollPreview from "./PollPreview";

const LinkedinPreview = ({ text, postFiles = null, poll }) => {
  const { t } = useTranslation();

  const styles = {
    "--artdeco-reset-typography-font-family-sans": `-apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', 'Fira Sans', Ubuntu, Oxygen, 'Oxygen Sans', Cantarell, 'Droid Sans', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Lucida Grande', Helvetica, Arial, sans-serif`,
  };

  return (
    <div
      style={{
        maxWidth: "555px",
        backgroundColor: "white",
        paddingLeft: "15px",
        paddingRight: "15px",

        border: "0.1rem solid lightgray",
        borderRadius: "8px",
        margin: "0 auto",
      }}
    >
      {text && text.length > 3000 && (
        <Row className="mb-3">
          <Col
            style={{
              color: "red",
              fontSize: "small",
            }}
          >
            {t("Backend.Post.Linkedin.LengthWarning")}
          </Col>
        </Row>
      )}
      <Row className="mb-3 mt-3">
        <Col md={1}>
          <img
            src="https://placehold.co/600x400/png"
            width={48}
            height={48}
            style={{ borderRadius: "50%" }}
          />
        </Col>
        <Col className="ms-2">
          <strong style={{ color: "black" }}>Your Name </strong>
          <br />
          <span style={{ fontSize: "smaller" }}>
            Here are 2 lines of profile text and more stuff that you can define
            yourself...
          </span>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <div
            style={{
              maxWidth: "515px",
              minHeight: "500px",
              color: "#191919",
              backgroundColor: "white",
              fontSize: "0.9rem",
              ...styles,
            }}
            dangerouslySetInnerHTML={{
              __html: text && text.replace(/\n/g, "<br />"),
            }}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        {postFiles && postFiles.length > 0 ? (
          <Col>
            <ImagesPreview postFiles={postFiles} />
          </Col>
        ) : (
          <Col>
            <PollPreview poll={poll} />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default LinkedinPreview;
