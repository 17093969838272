const MessagesEndpoints = {
  index: "social/outreach/messages/",
  new: "social/outreach/messages/new",
  edit: "social/outreach/messages/edit",
  show: "social/outreach/messages/show/",
  delete: "social/outreach/messages/delete/",
  setActive: "social/outreach/messages/setactive/",
  getSent: "social/outreach/messages/sent",
  getSentRequests: "social/outreach/accountability/connections/sent",
  dataField: "messages"
};
export default MessagesEndpoints;
