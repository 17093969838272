const PostAssistant= {
    title: "",
    text: "",
    post_type: "text",
    content_type: "personal",
    distinction: "informal",
    platform: "linkedin",
    tone: "formal",
    language: "german"
    };
  
  export default PostAssistant;
  