import { Routes, Route } from "react-router-dom";
import NotFoundPage from "../shared/404";
import Dashboard from "./Dashboard";
import AdminDashboard from "./AdminDashboard";
import BackendLayout from "./BackendLayout";
import Callbacks from "./Callbacks";
import LoggingIn from "./LogingIn";
import Timeline from "./Timeline";
import CalendarWrapper from "./components/CalendarWrapper";
import Filemanager from "./Filemanager";
import OutreachRouter from "./components/Outreach/OutReachRouter";
import AccountabilityRouter from "./components/Accountability/AccountabilityRouter";

const BackendRouter = () => {
  return (
    <Routes>
      <Route element={<BackendLayout />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/callbacks/*" element={<Callbacks />} />
        <Route path="/login" element={<LoggingIn />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/admindashboard" element={<AdminDashboard />} />
        <Route path="/timeline" element={<Timeline />} />
        <Route path="/calendar" element={<CalendarWrapper />} />
        <Route path="/files" element={<Filemanager />} />
        <Route path="/outreach/*" element={<OutreachRouter />} />
        <Route path="/accountability/*" element={<AccountabilityRouter />} />
        <Route path="/*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default BackendRouter;
