import { useTranslation } from "react-i18next";
import CompleteDropdown from "../../../shared/Dropdown/CompleteDropdown";
import { formatDate } from "@serie3/common/Shared/timeingStuff";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const FileListItem = ({
  file,
  index,
  deleteFile,
  viewFile,
  handleFileFunction = null,
  showPreview = false,
  dragable = false,
}) => {
  const { t } = useTranslation();
  return (
    <tr>
      <td>
        <a
          className="text-dark fw-medium"
          href="#"
          onClick={(e) => {

            if (typeof viewFile === "function") {
              viewFile(file);
            }
            e.preventDefault();
          }}
        >
          <i className="fas fa-file-alt align-middle text-primary me-3"></i>
          {file.original_name}
        </a>
      </td>
      <td>{formatDate(file.date)}</td>
      <td>{file.file_size}</td>
      <td>
        <CompleteDropdown
          {...(deleteFile && { deleteAction: () => deleteFile(index, file) })}
          {...(viewFile && { viewAction: () => viewFile(file) })}
          addFront={true}
        >
          {typeof handleFileFunction === "function" && (
            <>
              <Dropdown.Item
                onClick={() => {
                  handleFileFunction(file);
                }}
                className="text-success"
              >
                <FontAwesomeIcon icon={faPlus} className="fs-6 me-2" />
                {t("Backend.Dropdown.Use")}
              </Dropdown.Item>
              <div className="dropdown-divider" />
            </>
          )}
        </CompleteDropdown>
      </td>
    </tr>
  );
};

export default FileListItem;
