import { Container, Row, Col } from "react-bootstrap";

const AuthContainer = ({ children, title, description }) => {
  return (
    <div className="authentication-bg min-vh-100">
      <Container>
        <div className="bg-overlay bg-white" style={{zIndex: "0"}}></div>
        <div className="d-flex flex-column min-vh-100 px-3 pt-4">
          <Row className="justify-content-center my-auto">
            <Col md={8} lg={6} xl={4}>
              <h5>{title}</h5>
              <p>{description}</p>
              {children}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default AuthContainer;
