const AccountabilityEndpoints = {
    index: "social/outreach/accountability/",
    current: "social/outreach/accountability/current",
    new: "social/outreach/accountability/new",
    edit: "social/outreach/accountability/edit",
    show: "social/outreach/accountability/show/",
    delete: "social/outreach/accountability/delete/",
    dataField: "accountabilities"
}

export default AccountabilityEndpoints;