import { useTranslation } from "react-i18next";
import { Row, Col, Button, Card } from "react-bootstrap";
import NewModal from "../../shared/Modals/NewModal";
import Carousel from "react-bootstrap/Carousel";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ContentTypeSelect from "../../Form/ContentTypeSelect";
import PostTypeSelect from "../../Form/PostTypeSelect";
import { useFormik } from "formik";
import PostAssistantForm from "@serie3/mymediaplanner/Modules/Social/Forms/PostAssistantForm";
import DistinctionTypeSelect from "../../Form/DistinctionType";
import TextField from "../../Form/TextField";
import TextArea from "../../Form/TextArea";
import LanguageTypeSelect from "../../Form/LanguageTypeSelect";
import NewButtonWithIcon from "../../shared/Buttons/NewButtonWithIcon";
import crudApi from "@serie3/common/api/crudApi";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import listHandler from "@serie3/common/Domain/listHandler";

const PostAssistant = ({
  show,
  setShow,
  setPosts,
  setEditPost,
  posts,
  setShowEdit,
  showSpinner,
  setShowSpinner,
}) => {
  const { t } = useTranslation();
  const [slideIndex, setSlideIndex] = useState(0);
  const [aiPost, setAiPost] = useState(null);
  const [stepTitle, setStepTitle] = useState(
    t("Backend.Post.Assistant.ChoosePlatform")
  );

  const postAssistantForm = PostAssistantForm.createPostAssistantForm(
    setAiPost,
    setShowSpinner
  );
  const formik = useFormik(postAssistantForm);

  const tones = t("Backend.Post.Tones", { returnObjects: true });

  const NavButtons = () => {
    return (
      <>
        {slideIndex > 0 && (
          <Button
            className="btn btn-danger"
            onClick={() => setSlideIndex(slideIndex - 1)}
          >
            {t("Backend.Post.Assistant.Back")}
          </Button>
        )}
        {slideIndex === 2 && (
          <Button
            className="btn btn-success"
            onClick={async () => {
              formik.handleSubmit();
            }}
          >
            {t("Backend.Post.Assistant.CreatePost")}
          </Button>
        )}
        {aiPost && (
          <NewButtonWithIcon
            title={t("Backend.Post.Assistant.Save")}
            handleClick={async () => {
              let response = await crudApi.createNew(
                SocialEndpoints.posts.new,
                { title: formik.values.title, text: aiPost }
              );
              if (response.ok) {
                listHandler.newToList(response, posts, setPosts, null, true);
                setEditPost(response.data);
                setShowEdit(true);
              }
            }}
          />
        )}
      </>
    );
  };

  const setStep1 = () => {
    setSlideIndex(1);
    setStepTitle(t("Backend.Post.Assistant.ChooseTone"));
  };

  const setStep2 = () => {
    setSlideIndex(2);
    setStepTitle(t("Backend.Post.Assistant.ChooseContentType"));
  };

  return (
    <NewModal
      show={show}
      setShow={setShow}
      onExit={() => {
        setSlideIndex(0);
        setStepTitle(t("Backend.Post.Assistant.ChoosePlatform"));
      }}
      title={stepTitle}
      ExtraButton={NavButtons}
    >
      <Carousel
        activeIndex={slideIndex}
        interval={null}
        controls={false}
        indicators={false}
      >
        <Carousel.Item>
          <Row>
            <Col onClick={() => setStep1()}>
              <FontAwesomeIcon icon={faLinkedin} size="3x" />
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            {Object.keys(tones).map((toneKey) => (
              <Col
                onClick={() => {
                  formik.setFieldValue("tone", toneKey);
                  setStep2();
                }}
              >
                <Card key={toneKey}>
                  <Card.Header>
                    <strong>{toneKey}</strong>
                  </Card.Header>
                  <Card.Body>{tones[toneKey].example}</Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            <Col>
              <ContentTypeSelect formik={formik} />
            </Col>

            <Col>
              <PostTypeSelect formik={formik} />
            </Col>
          </Row>
          <Row>
            <Col>
              <DistinctionTypeSelect formik={formik} />
            </Col>

            <Col>
              <LanguageTypeSelect formik={formik} />
            </Col>
          </Row>
          <hr />
          <Row className="mt-3">
            <Col>
              <TextField
                formik={formik}
                valueName={"title"}
                label={t("Backend.Post.Assistant.Title")}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextArea
                formik={formik}
                valueName={"text"}
                label={t("Backend.Post.Assistant.Draft")}
                rows={10}
              />
            </Col>
          </Row>
          <hr />
          <h4>{t("Backend.Post.Assistant.AIPost")}</h4>
          <Row>
            <Col style={{ backgroundColor: "lightgray", padding: "25px" }}>
              <span
                
                dangerouslySetInnerHTML={{
                  __html: aiPost && aiPost.replace(/\n/g, "<br />"),
                }}
              />
            </Col>
          </Row>
        </Carousel.Item>
      </Carousel>
    </NewModal>
  );
};

export default PostAssistant;
