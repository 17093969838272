import { useTranslation } from "react-i18next";
import SelectField from "./SelectField";

const ContentTypeSelect = ({ formik }) => {
  const { t } = useTranslation();
  return (
    <SelectField
      formik={formik}
      label={t("Backend.Post.ContentType.Title")}
      valueName={"content_type"}
      style={{ marginBottom: "10px" }}
      value={formik.values.content_type}
    >
      <option value="Personal">{t("Backend.Post.ContentType.Personal")}</option>
      <option value="Business">{t("Backend.Post.ContentType.Business")}</option>
      <option value="Technical">
        {t("Backend.Post.ContentType.Technical")}
      </option>
      <option value="Educational">
        {t("Backend.Post.ContentType.Educational")}
      </option>
      <option value="News">{t("Backend.Post.ContentType.News")}</option>
      <option value="Creative">{t("Backend.Post.ContentType.Creative")}</option>
      <option value="Social">{t("Backend.Post.ContentType.Social")}</option>
      <option value="Entertainment">
        {t("Backend.Post.ContentType.Entertainment")}
      </option>
      <option value="Marketing">
        {t("Backend.Post.ContentType.Marketing")}
      </option>
      <option value="Instructional">
        {t("Backend.Post.ContentType.Instructional")}
      </option>
    </SelectField>
  );
};

export default ContentTypeSelect;
