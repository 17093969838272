import { useState } from "react";

export default function useToken() {
  const logout = () => {
    const event = new CustomEvent("logoutMediaplanner");
    window.dispatchEvent(event);
    // Remove items from localStorage
    localStorage.removeItem("token");
    localStorage.removeItem("localId");
    localStorage.removeItem("loginTime");
    localStorage.removeItem("roles");
    // Update the state to reflect the logout
    setToken(null);
    window.location.replace("/");
  };

  const getToken = () => {
    const tokenString = localStorage.getItem("token");
    const localId = localStorage.getItem("localId");
    const userToken = JSON.parse(tokenString);
    const uuid = JSON.parse(localId);
    return userToken;
  };

  const getRoles = () => {
    const roles = localStorage.getItem("roles");
    if (typeof roles !== "undefined") {
      return JSON.parse(roles);
    }
    return [];
  };

  const [token, setToken] = useState(getToken());
  const [roles, setRoles] = useState(getRoles());

  const dispatchTokenEvent = (userToken, localId) => {
    const event = new CustomEvent("tokenAvailable", {
      detail: {
        token: userToken,
        localId: localId,
        sourceURL: window.location.href, // Include the current URL
        loginTime: Date.now(), // Include the current timestamp as the login time
      },
    });
    window.dispatchEvent(event);
  };

  const saveToken = (userToken, localId, roles = []) => {
    if (!userToken || !localId) {
      logout();
    } else {
      localStorage.setItem("token", JSON.stringify(userToken));
      localStorage.setItem("localId", JSON.stringify(localId));
      localStorage.setItem("roles", JSON.stringify(roles));
      localStorage.setItem("loginTime", String(Date.now()));
      setToken(userToken);
      dispatchTokenEvent(userToken, localId);
    }
  };

  if (
    token &&
    Math.abs(Date.now() - Number(localStorage.getItem("loginTime"))) / 60000 >=
      59
  ) {
    logout();

  }

  return {
    setToken: saveToken,
    token,
    logout,
    roles,
  };
}
