import { Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import TextField from "../../Form/TextField";
import TextArea from "../../Form/TextArea";
import { useTranslation } from "react-i18next";
import NewModal from "../../shared/Modals/NewModal";
import FileDropzone from "../../shared/Modals/FileDropzone";
import { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import crudApi from "@serie3/common/api/crudApi";
import LoadingModal from "../../shared/Modals/LoadingModal";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LinkedinPreview from "./Previews/LinkedinPreview";
import ContentTypeSelect from "../../Form/ContentTypeSelect";
import PostTypeSelect from "../../Form/PostTypeSelect";
import NewButtonWithIcon from "../../shared/Buttons/NewButtonWithIcon";
import Filemanager from "../Filemanager";
import { useEffect } from "react";
import DocumentsEndpoints from "@serie3/mymediaplanner/Modules/Documents/endpoints";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DragableFileListItem from "./Filemanager/DragableFileListItem";
import AIDropdownMenu from "./SubComponents/AIDropdownMenu";
import listHandler from "@serie3/common/Domain/listHandler";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import PollFormView from "./Poll/PollFormView";
import ToastMessages from "../../../ToastMessages";

const ComposePostTab = ({ formik, editData }) => {
  const { t } = useTranslation();
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showFileManager, setShowFilemanager] = useState(false);
  const [postFiles, setPostFiles] = useState([]);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [textCheckResult, setTextCheckResult] = useState({});
  const [showTextChecker, setShowTextChecker] = useState(false);
  const [poll, setPoll] = useState(editData.poll);

  useEffect(() => {
    const loadPostDocuments = async () => {
      const response = await crudApi.getAll(
        DocumentsEndpoints.showPostFiles + editData.id
      );
      if (response.ok) {
        setPostFiles(response.data);
      }
    };
    loadPostDocuments();
  }, []);

  const moveRow = (dragIndex, hoverIndex) => {
    const updatedFiles = [...postFiles];
    const [draggedFile] = updatedFiles.splice(dragIndex, 1); // Remove dragged item
    updatedFiles.splice(hoverIndex, 0, draggedFile); // Insert dragged item in new position
    setPostFiles(updatedFiles);
  };

  const dropFunction = (dragedItem) => {
    let index = dragedItem.index;
    const previousFile = postFiles[index - 1] || null; // Get the file before (or null if it's the first)
    const movedElement = postFiles[index]; // The dropped file
    const afterFile = postFiles[index + 1] || null;

    const data = {};
    if (previousFile !== null) {
      data.before = previousFile.id;
    }
    if (afterFile !== null) {
      data.after = afterFile.id;
    }
    data.moved_element = movedElement.id;

    crudApi.createNew(DocumentsEndpoints.sort, data);
  };

  const handleTextChange = (e) => {
    const { selectionStart } = e.target;
    setCursorPosition(selectionStart);
    formik.setFieldValue("text", e.target.value);
  };

  const connectFileWithPost = async (file) => {
    const itemIndex = postFiles.findIndex((obj) => obj.document.id === file.id);
    if (itemIndex > -1) {
      ToastMessages.NotAllowed('File already connected to post');
    } else {
      const response = await crudApi.justGet(
        DocumentsEndpoints.connectFileToPost + editData.id + "/" + file.id
      );
      if (response.ok) {
        listHandler.newToList(response, postFiles, setPostFiles);
        ToastMessages.SuccessfulSave();
      } else {
        ToastMessages.NotAllowed();
      }
    }
  };

  const removeFilesFromPost = async (connection) => {
    const itemIndex = postFiles.findIndex((obj) => obj.id === connection.id);
    listHandler.deleteFromList(
      itemIndex,
      crudApi.del(DocumentsEndpoints.removeFileFromPost, connection.id),
      setPostFiles,
      postFiles
    );
  };

  const addPoll = async (id) => {
    const response = await crudApi.createNew(
      SocialEndpoints.posts.poll.new + editData.id,
      {}
    );
    if (response.ok) {
      console.log(response.data);
      setPoll({ id: response.data.id });
      editData.poll = { id: response.data.id };
    }
  };

  const deletePoll = async () => {
    const response = await crudApi.del(
      SocialEndpoints.posts.poll.delete,
      editData.poll.id
    );
    if (response.ok) {
      setPoll(null);
      editData.poll = null;
    }
  };

  return (
    <>
      <NewModal
        size="xl"
        show={showTextChecker}
        setShow={setShowTextChecker}
        title={t("Backend.Post.CheckTextHeader")}
      >
        <Row>
          <Col>
            {textCheckResult.changes &&
              textCheckResult.changes.map((change, index) => (
                <Card key={index}>
                  <Card.Header>
                    <h5>
                      #{index + 1} {change.type}
                    </h5>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <i>{change.original}</i> ⇒{" "}
                        <strong>{change.change}</strong>
                      </Col>
                    </Row>
                    <Row className="mt-5">
                      <Col>
                        <strong className="me-2">
                          {t("Backend.Post.ChangingReason")}
                        </strong>
                        {change.reason}
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>
                    <Button
                      onClick={() => {
                        const { text } = formik.values;
                        const updatedText = text.replace(
                          change.original,
                          change.change
                        );
                        formik.setFieldValue("text", updatedText);
                      }}
                    >
                      {t("Backend.Post.ApplyChange")}
                    </Button>
                  </Card.Footer>
                </Card>
              ))}
          </Col>
          <Col
            dangerouslySetInnerHTML={{
              __html:
                formik.values.text &&
                formik.values.text.replace(/\n/g, "<br />"),
            }}
          />
        </Row>
      </NewModal>

      <NewModal size="xl" show={showFileManager} setShow={setShowFilemanager}>
        <Filemanager handleFileFunction={connectFileWithPost} />
      </NewModal>

      <NewModal show={showFileUpload} setShow={setShowFileUpload}>
        <FileDropzone
          uploadUrl={DocumentsEndpoints.addToPost + editData.id}
          setName={false}
          setData={setPostFiles}
          data={postFiles}
          edit={false}
          additionalData={{}}
        />
      </NewModal>
      <LoadingModal
        showSpinner={showLoadingModal}
        setShowSpinner={setShowLoadingModal}
        text={t("Backend.Post.AIworking")}
      />

      <Row>
        <Col md={6}>
          <Card>
            <Card.Body>
              <Tabs
                defaultActiveKey="write"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="write" title={t("Backend.Post.Tabs.Text")}>
                  <Row>
                    <Col>
                      <TextField
                        formik={formik}
                        label={t("Backend.Post.Title")}
                        valueName={"title"}
                        style={{ marginBottom: "10px" }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-3">
                      <TextArea
                        formik={formik}
                        label=" "
                        valueName={"text"}
                        style={{ marginBottom: "10px" }}
                        rows={35}
                        onClick={(e) => handleTextChange(e)}
                        LabelButton={() => (
                          <AIDropdownMenu
                            charCount={
                              formik.values.text && formik.values.text.length
                            }
                            formik={formik}
                            setShowLoadingModal={setShowLoadingModal}
                            cursorPosition={cursorPosition}
                            setCursorPosition={setCursorPosition}
                            editData={editData}
                            setTextCheckResult={setTextCheckResult}
                            setShowTextChecker={setShowTextChecker}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="images" title={t("Backend.Post.Tabs.Images")}>
                  {postFiles.length > 0 ||
                  (!editData.poll && postFiles.length == 0) ? (
                    <>
                      <Row>
                        <Col className="d-flex justify-content-start">
                          <NewButtonWithIcon
                            className="me-2"
                            title={t("Backend.Post.AddFile")}
                            handleClick={() => {
                              setShowFilemanager(true);
                            }}
                          />
                          <NewButtonWithIcon
                            title={t("Backend.Post.UploadFile")}
                            icon={faUpload}
                            handleClick={() => setShowFileUpload(true)}
                          />
                        </Col>
                      </Row>
                      <hr />

                      <Row>
                        <Col>
                          <DndProvider backend={HTML5Backend}>
                            <Table>
                              <head>
                                <tr>
                                  <td> </td>
                                </tr>
                              </head>
                              <tbody>
                                {postFiles.map((connection, index) => {
                                  return (
                                    <DragableFileListItem
                                      deleteFile={removeFilesFromPost}
                                      file={connection}
                                      index={index}
                                      showPreview={true}
                                      moveRow={moveRow}
                                      dropFunction={dropFunction}
                                      key={connection.id}
                                    />
                                  );
                                })}
                              </tbody>
                            </Table>
                          </DndProvider>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    t("Backend.Post.Poll.PollSet")
                  )}
                </Tab>

                <Tab eventKey="poll" title={t("Backend.Post.Tabs.Poll")}>
                  {editData.poll ||
                  (!editData.poll && postFiles.length == 0) ? (
                    <>
                      {!editData.poll ? (
                        <Row>
                          <Col>
                            <NewButtonWithIcon
                              className="me-2"
                              title={t("Backend.Post.Poll.AddPoll")}
                              handleClick={() => {
                                addPoll(editData.id);
                              }}
                            />
                          </Col>
                        </Row>
                      ) : (
                        <>
                          <Row className="mb-5">
                            <Col>
                              <Button
                                className="btn btn-danger"
                                onClick={() => {
                                  deletePoll();
                                }}
                              >
                                {t("Backend.Post.Poll.DeletePoll")}
                              </Button>
                            </Col>
                          </Row>
                          <PollFormView poll={poll} setPoll={setPoll} />
                        </>
                      )}
                    </>
                  ) : (
                    t("Backend.Post.Poll.FilesSet")
                  )}
                </Tab>

                <Tab
                  eventKey="settings"
                  title={t("Backend.Post.Tabs.Settings")}
                >
                  <Row>
                    <Col>
                      <ContentTypeSelect formik={formik} />
                    </Col>
                    <Col>
                      <PostTypeSelect formik={formik} />
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Tabs
                defaultActiveKey="linkedIn"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab
                  eventKey="linkedIn"
                  title={<FontAwesomeIcon icon={faLinkedin} size="2x" />}
                  style={{
                    backgroundColor: "#f4f2ee",
                    paddingBottom: "15px",
                    paddingTop: "15px",
                  }}
                >
                  <LinkedinPreview
                    text={formik.values.text}
                    postFiles={postFiles}
                    poll={poll}
                  />
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ComposePostTab;
