import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import NewModal from "./Modals/NewModal";

const EmojiPicker = ({ handleAddEmoji }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <Button
        className="btn btn-light"
        ref={target}
        onClick={() => setShow(!show)}
      >
        🤣
      </Button>
      <Modal
        show={show}
        setShow={setShow}
        size={"sm"}
        dialogClassName="modal-90w"
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Body>
          <Picker data={data} onEmojiSelect={handleAddEmoji} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmojiPicker;
