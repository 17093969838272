import { Outlet, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import Sidebar from "./Sidebar";

import { useState, useEffect } from "react";
import Header from "./Header";

const BackendLayout = ({ token, setToken }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setShowSidebar(false);
  }, [location]);

  return (
    <>
      <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar}/>
      <Sidebar showSidebar={showSidebar}/>
      <div className="main-content">
        <div className="page-content">
          <Container fluid>
            <Outlet />
          </Container>
        </div>
      </div>
    </>
  );
};

export default BackendLayout;
