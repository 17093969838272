import { useTranslation } from "react-i18next";
import { Row, Col, Card, CardBody } from "react-bootstrap";
import crudApi from "@serie3/common/api/crudApi";
import { useEffect, useState } from "react";
import MessagesEndpoints from "@serie3/mymediaplanner/Modules/Messages/endpoints";
import AddButton from "../../../shared/Buttons/AddButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCircle,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import NewModal from "../../../shared/Modals/NewModal";
import MessageForm from "@serie3/mymediaplanner/Modules/Messages/Forms/MessageForm";
import SaveButton from "../../../shared/Buttons/SaveButton";
import { useFormik } from "formik";
import Message from "@serie3/mymediaplanner/Modules/Messages/Entity/Message";
import TextArea from "../../../Form/TextArea";
import TextField from "../../../Form/TextField";
import listHandler from "@serie3/common/Domain/listHandler";
import ToastMessages from "../../../../ToastMessages";

const Messages = () => {
  const { t } = useTranslation();
  const [messages, setMessages] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [editMessage, setEditMessage] = useState();

  const AddBtn = () => {
    return (
      <>
        <AddButton
          addFunction={() => {
            setEditMessage(null);
            setShowEdit(true);
          }}
        />
      </>
    );
  };

  useEffect(() => {
    const fetchMessags = async () => {
      await crudApi.getAll(MessagesEndpoints.index, setMessages, "messages");
    };
    fetchMessags();
  }, []);

  const editMessageForm = MessageForm.createMessageForm(
    messages,
    setMessages,
    setShowEdit
  );

  const formik = useFormik(editMessageForm);

  const Save = () => {
    return (
      <>
        <SaveButton
          submitFunction={(e) => {
            formik.handleSubmit(e);
            e.preventDefault();
          }}
        />
      </>
    );
  };

  const setActive = async (item) => {
    const response = await crudApi.justGet(
      MessagesEndpoints.setActive + item.id
    );
    if (response.ok) {
      listHandler.replaceItemInList(response.data, messages, setMessages);

      for (const message of messages) {
        if (message.id !== item.id) {
          try {
            if (message.active) {
              const response = await crudApi.justGet(
                MessagesEndpoints.setActive + message.id
              );
              listHandler.replaceItemInList(
                response.data,
                messages,
                setMessages
              );
            }
          } catch (error) {
            console.error("Error fetching message:", error);
          }
        }
      }

      ToastMessages.SuccessfulSave("Active message changed");
    }
  };

  return (
    <>
      <NewModal
        show={showEdit}
        setShow={setShowEdit}
        title={formik.values.title}
        ExtraButton={Save}
        onShow={() => {
          editMessage
            ? formik.setValues(MessageForm.setEditData(editMessage))
            : formik.setValues(Message);
        }}
        onExit={() => {
          formik.resetForm({ values: { Message } });
        }}
      >
        <Row>
          <Col>
            <TextField
              formik={formik}
              label={t("Backend.Post.Title")}
              valueName={"title"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextArea
              formik={formik}
              label=" "
              valueName={"text"}
              style={{ marginBottom: "10px" }}
              rows={10}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mt-3">
            <b>HINT:</b> Use {"{{firstname}}"} a placeholder for the first name
            of your contact. No other placeholders are available right now!
          </Col>
        </Row>
      </NewModal>
      <h4 className="mb-sm-0">{t("Backend.Outreach.Messages.Messages")}</h4>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-end">
                <AddBtn />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="table-responsive react-table">
                <table className="custom-header-css table table-hover">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Title</th>
                      <th>Message</th>
                      <th style={{ textAlign: "center" }}>Active</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {messages.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td> #{index + 1} </td>
                          <td>{item.title}</td>
                          <td>{item.text}</td>
                          <td style={{ textAlign: "center"}}>
                            {item.active ? (
                              <FontAwesomeIcon
                                style={{cursor:"pointer"}}
                                className="text-success"
                                icon={faCheck}
                                onClick={() => {
                                  setActive(item);
                                }}
                              />
                            ) : (
                              <FontAwesomeIcon
                                style={{cursor:"pointer"}}
                                className="text-danger"
                                icon={faCircle}
                                onClick={() => {
                                  setActive(item);
                                }}
                              />
                            )}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <FontAwesomeIcon
                              style={{cursor:"pointer"}}
                              icon={faEdit}
                              onClick={() => {
                                setEditMessage(item);
                                setShowEdit(true);
                              }}
                            />
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <FontAwesomeIcon
                              style={{cursor:"pointer"}}
                              icon={faTrash}
                              onClick={() => {
                                const deleteUrl = MessagesEndpoints.delete;
                                listHandler.deleteFromList(
                                  index,
                                  crudApi.del(deleteUrl, item.id),
                                  setMessages,
                                  messages
                                );
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default Messages;
