import * as Yup from "yup";
import Login from "../../Entity/Login/Login";
import firebaseConfig from "@serie3/mymediaplanner/Config/Firebase/firebase";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import apiClient from "../../api/client";
import UserConfig from "../../Modules/User/Resources/Endpoints";


const validationSchema = Yup.object().shape({
  username: Yup.string().required().email().label("Email"),
  password: Yup.string().required().min(4).label("Password"),
});

const CreateLoginForm = (setToken, showLoginError, closeModal) => {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  return {
    initialValues: Login,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await signInWithEmailAndPassword(
          auth,
          values.username,
          values.password
        );

        if (response._tokenResponse.idToken) {
          const token = response._tokenResponse.idToken;
          const localId = response._tokenResponse.localId;
          setToken(token, localId);

          const rolesResponse = await apiClient.get(
            UserConfig.endpoints.settings.roles
          );

          if (rolesResponse.status === 200) {
            setToken(token, localId, rolesResponse.data);
          }

          if (typeof closeModal !== "undefined") {
            closeModal();
          }
        }
        
      } catch (error) {
        console.log(`There was an error: ${error}`);
        showLoginError(true);
      }
    },
  };
};

export default CreateLoginForm;
