import { useTranslation } from "react-i18next";
import { Row, Col, Table } from "react-bootstrap";
import AccountabilityEndpoints from "@serie3/mymediaplanner/Modules/Accountability/endpoints";
import crudApi from "@serie3/common/api/crudApi";
import { useEffect, useState } from "react";
import { formatDate } from "@serie3/common/Shared/timeingStuff";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import NewModal from "../../../shared/Modals/NewModal";
import { useFormik } from "formik";
import AccountabilityForm from "@serie3/mymediaplanner/Modules/Accountability/Forms/AccountabilityForm";
import Accountability from "@serie3/mymediaplanner/Modules/Accountability/Entity/Accountability";
import NumberField from "../../../Form/NumberField";
import TextField from "../../../Form/TextField";
import SaveButton from "../../../shared/Buttons/SaveButton";

const Current = ({ props }) => {
  const { t } = useTranslation();
  const [accountability, setAccountability] = useState(null);
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const response = await crudApi.justGet(AccountabilityEndpoints.current);
      if (response.ok) {
        setAccountability(response.data);
      }
    };
    getData();
  }, []);

  const editForm =
    AccountabilityForm.createAccountabilityForm(setAccountability);
  const formik = useFormik(editForm);


  const Save = () => {
    return (
      <>
        <SaveButton
          submitFunction={(e) => {
            formik.handleSubmit(e);
            e.preventDefault();
          }}
        />
      </>
    );
  };

  return (
    accountability && (
      <>
        <NewModal
          show={showEdit}
          setShow={setShowEdit}
          title={t("Backend.Accountability.Modal.Title")}
          ExtraButton={Save}
          onShow={() => {
            formik.setValues(AccountabilityForm.setEditData(accountability));
          }}
          onExit={() => {
            formik.resetForm({ values: { Accountability } });
          }}
        >
          <Row>
            <Col>
              <TextField
                formik={formik}
                label={t("Backend.Accountability.Labels.Platform")}
                valueName={"platform"}
              />
            </Col>
            <Col>
              <NumberField
                formik={formik}
                label={t("Backend.Accountability.Labels.Posts")}
                valueName={"post_amount"}
                step={1}
              />
            </Col>
            <Col>
              <NumberField
                formik={formik}
                label={t("Backend.Accountability.Labels.Requests")}
                valueName={"request_amount"}
                step={1}
              />
            </Col>
            <Col>
              <NumberField
                formik={formik}
                label={t("Backend.Accountability.Labels.Icebreaker")}
                valueName={"messages_amount"}
                step={1}
              />
            </Col>
          </Row>
        </NewModal>
        <Row>
          <Col>
          <div className="table-responsive react-table">
            <Table className="custom-header-css table table-hover">
              <thead>
                <tr>
                  <th>{t("Backend.Accountability.Labels.Valid")}</th>
                  <th>{t("Backend.Accountability.Labels.Platform")}</th>
                  <th>{t("Backend.Accountability.Labels.Posts")}</th>
                  <th>{t("Backend.Accountability.Labels.Requests")}</th>
                  <th>{t("Backend.Accountability.Labels.Icebreaker")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr key={accountability.id}>
                  <td>{formatDate(accountability.valid)}</td>
                  <td>{accountability.platform}</td>
                  <td>
                    {accountability.reached_post_amount
                      ? accountability.reached_post_amount
                      : 0}{" "}
                    / {accountability.post_amount}
                  </td>
                  <td>
                    {accountability.reached_request_amount
                      ? accountability.reached_request_amount
                      : 0}{" "}
                    / {accountability.request_amount}
                  </td>
                  <td>
                    {accountability.reached_messages_amount
                      ? accountability.reached_messages_amount
                      : 0}{" "}
                    / {accountability.messages_amount}
                  </td>
                  <td>
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => setShowEdit(true)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            </div>
          </Col>
        </Row>
      </>
    )
  );
};

export default Current;
