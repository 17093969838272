import { useTranslation } from "react-i18next";
import { Row, Col, Table } from "react-bootstrap";

const StatsTab = ({ stats }) => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col>
        <Table striped>
          <thead>
            <tr>
              <th>{t("Backend.Post.Stats.Platform")}</th>
              <th>{t("Backend.Post.Stats.Views")}</th>
              <th>{t("Backend.Post.Stats.Likes")}</th>
              <th>{t("Backend.Post.Stats.Comments")}</th>
            </tr>
          </thead>
          <tbody>
            {stats.map((stat, index) => {
              return (
                <tr key={index}>
                  <td>{stat["platform"] && stat["platform"]}</td>
                  <td>{stat["views"] && stat["views"]}</td>
                  <td>{stat["likes"] && stat["likes"]}</td>
                  <td>{stat["comments"] && stat["comments"]}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default StatsTab;
