import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import Accountability from "../Entity/Accountability";
import AccountabilityEndpoints from "../endpoints";

const createAccountabilityForm = (setDataFunction) => {
  const validationSchema = Yup.object({
    Tipp: Yup.string().required("Required"),
  });

  const editFunction = async (values, originalValues) => {
    let payload = JSON.stringify(values, null, 2);
    const response = await CrudApi.edit(AccountabilityEndpoints.edit, payload);
    if (response.ok) {
      setDataFunction(response.data);
    }
  };

  const onSubmit = async (values) => {
    const originalValues = { ...values };
    if (values.id > 0) {
      editFunction(values, originalValues);
    }
  };

  return {
    initialValues: Accountability,
    onSubmit: (values, actions) => {
      onSubmit(values);
    },
  };
};

const setEditData = (editData) => {
  let Accountability = {
    id: editData.id,
    platform: editData.platform,
    post_amount: editData.post_amount,
    request_amount: editData.request_amount,
    messages_amount: editData.messages_amount,
  };

  return Accountability;
};

export default { createAccountabilityForm, setEditData };
