import createRequestValidationForm from "@serie3/common/Form/Register/RequestValidationForm";
import { useFormik } from "formik";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthContainer from "./AuthContainer";

const RequestValidationLink = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const requestValidationForm = createRequestValidationForm(setShowSuccess);
  const formik = useFormik(requestValidationForm);

  const emailFieldRef = useRef(null);

  const { t } = useTranslation();

  return (
    <AuthContainer
      title={t("Login.Request Verify Link.Title")}
      description={t("Login.Request Verify Link.Subtitle")}
    >
      <Form className="mt-4" onSubmit={formik.handleSubmit}>
        <Form.Control
          placeholder={t("Login.Request Verify Link.Placeholder")}
          type={"text"}
          ref={emailFieldRef}
        />

        <div className="d-grid mt-5">
          <button
            className="btn btn-info w-100"
            type="submit"
            onClick={() => {
              setShowSuccess(false);
            }}
          >
            {t("Login.Request Verify Link.Button")}
          </button>
        </div>
      </Form>
      <div className="d-flex justify-content-between mt-4">
        <Link to={"/login"} className="btn-link text-decoration-none">
          {t("Login.Login")}
        </Link>
        <Link to={"/signup"} className="btn-link text-decoration-none">
          {t("Login.New Account")}
        </Link>
      </div>
      <div className="align-items-center text-center border-top pt-3 mt-3">
        {showSuccess ? (
          <span style={{ color: "green" }}>{t("Login.Messages.Success")} </span>
        ) : (
          ""
        )}
      </div>
    </AuthContainer>
  );
};

export default RequestValidationLink;
