import TextField from "../../Form/TextField";
import Checkbox from "../../Form/Checkbox";
import { useTranslation } from "react-i18next";
import CreateLoginForm from "@serie3/common/Form/Login/CreateLoginForm";
import { useState } from "react";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthContainer from "./AuthContainer";

const Login = ({ setShow, setToken, token = null }) => {
  const { t } = useTranslation();

  const closeModal = () => {
    if (typeof setShow === "function") {
      setShow(false);
    }
  };

  const [showLoginError, setShowLoginError] = useState(false);
  const loginForm = CreateLoginForm(setToken, setShowLoginError, closeModal);
  const formik = useFormik(loginForm);

  return (
    <AuthContainer title={t("Login.Title")} description={t("Login.Subtitle")}>
      <Form className="mt-4">
        <div className="mb-3">
          <TextField
            placeholder={t("Login.Email")}
            formik={formik}
            valueName={"username"}
          />
        </div>
        <div className="mb-3">
          <TextField
            placeholder={t("Login.Password")}
            formik={formik}
            valueName={"password"}
            password={true}
          />
        </div>
        <Checkbox label={t("Login.Remember me")} />
        <div className="d-grid mt-5">
          <button
            className="btn btn-info w-100"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              formik.handleSubmit();
              setShowLoginError(false);
            }}
          >
            {t("Login.SignInButton")}
          </button>
        </div>
      </Form>
      {!token && (
        <div className="d-flex justify-content-between mt-4">
          <Link
            to={"/resetpassword"}
            onClick={() => {
              setShow(false);
            }}
            className="btn-link text-decoration-none mr-3"
          >
            {t("Login.ForgotLink")}
          </Link>
          <Link
            to={"/signup"}
            onClick={() => {
              setShow(false);
            }}
            className="btn-link text-decoration-none"
          >
            {t("Login.New Account")}
          </Link>
        </div>
      )}
      <div
        className="align-items-center justify-content-between text-centered border-top pt-3 mt-3"
        style={{ color: "red" }}
      >
        {showLoginError ? t("Login.Messages.Error") : ""}
      </div>
    </AuthContainer>
  );
};

export default Login;
