import { useTranslation } from "react-i18next";
import CompleteDropdown from "../../../shared/Dropdown/CompleteDropdown";
import { formatDate } from "@serie3/common/Shared/timeingStuff";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useDrag, useDrop } from "react-dnd";

const DragableFileListItem = ({
  file,
  index,
  deleteFile,
  viewFile,
  useFunction = null,
  showPreview = false,
  dragable = false,
  moveRow,
  dropFunction = null,
}) => {
  const { t } = useTranslation();

  const [{ isDragging }, dragRef] = useDrag({
    type: "row",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: "row",
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveRow(draggedItem.index, index);
        draggedItem.index = index; // Update the index of the dragged item
      }
    },
    drop: (draggedItem) => {
      // This is executed when the item is dropped
      dropFunction(draggedItem);
    },
  });

  return (
    <tr
      ref={(node) => dragRef(dropRef(node))}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
      }}
    >
      <td>
      <a
          className="text-dark fw-medium"
          href="#"
          onClick={(e) => {

            if (typeof showPreview === "function") {
              showPreview(file);
            }
            e.preventDefault();
          }}
        >
          <i className="fas fa-file-alt align-middle text-primary me-3"></i>
          {file.document.original_name}
        </a>
      </td>
      <td>{formatDate(file.document.date)}</td>
      <td>{file.document.file_size}</td>
      <td>
        {!dragable ? (
          <CompleteDropdown
            {...(deleteFile && { deleteAction: () => deleteFile(file, index) })}
            {...(viewFile && { viewAction: () => viewFile(file) })}
            addFront={true}
          >
            {typeof useFunction === "function" && (
              <>
                <Dropdown.Item onClick={useFunction} className="text-success">
                  <FontAwesomeIcon icon={faPlus} className="fs-6 me-2" />
                  {t("Backend.Dropdown.Use")}
                </Dropdown.Item>
                <div className="dropdown-divider" />
              </>
            )}
          </CompleteDropdown>
        ) : null}
      </td>
    </tr>
  );
};

export default DragableFileListItem;
