import { useTranslation } from "react-i18next";
import SelectField from "./SelectField";

const PostTypeSelect = ({ formik }) => {
  const { t } = useTranslation();
  return (
    <SelectField
      formik={formik}
      label={t("Backend.Post.PostType.Title")}
      valueName={"post_type"}
      style={{ marginBottom: "10px" }}
      value={formik.values.post_type}
    >
      <option value="Text">{t("Backend.Post.PostType.Text")}</option>
      <option value="TextImage">{t("Backend.Post.PostType.TextImage")}</option>
      <option value="Carousel">{t("Backend.Post.PostType.Carousel")}</option>
      <option value="Video">{t("Backend.Post.PostType.Video")}</option>
      <option value="Shortform">{t("Backend.Post.PostType.Shortform")} </option>
      <option value="Stories">{t("Backend.Post.PostType.Stories")}</option>
      <option value="Poll">{t("Backend.Post.PostType.Poll")}</option>
      <option value="LinkPost">{t("Backend.Post.PostType.LinkPost")}</option>
      <option value="Infographic">
        {t("Backend.Post.PostType.Infographic")}
      </option>
    </SelectField>
  );
};

export default PostTypeSelect;
