import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import crudApi from "@serie3/common/api/crudApi";
import DashboardEndpoints from "@serie3/mymediaplanner/Modules/Dashboard/endpoints";
import ListElement from "./ListElement";

const Accountability = () => {
  const { t } = useTranslation();

  const [planData, setPlanData] = useState(null);
  useEffect(() => {
    const getData = async () => {
      const response = await crudApi.justGet(DashboardEndpoints.outreach);
      if (response.ok) {
        setPlanData(response.data);
      }
    };

    getData();
  }, []);

  return (
    <Card>
      <Card.Body>
        <div>
          <ul className="list-group list-group-flush">
            {planData && (
              <ListElement
                title={t("Backend.Dashboard.SendRequests")}
                data={planData.connectionRequestsCount}
                icon="uil uil-user-plus"
              />
            )}
            {planData && (
              <ListElement
                title={t("Backend.Dashboard.SendMessages")}
                data={planData.messageCount}
                icon="uil uil-envelope"
              />
            )}
          </ul>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Accountability;
