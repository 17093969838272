import { useTranslation } from "react-i18next";
import { Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import crudApi from "@serie3/common/api/crudApi";
import DashboardEndpoints from "@serie3/mymediaplanner/Modules/Dashboard/endpoints";
import DonutChart from "../../Charts/DonutChart";

const PostTypes = () => {
  const { t } = useTranslation();
  const [types, setTypes] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const response = await crudApi.justGet(DashboardEndpoints.posts);

      if (response.ok) {
        const countTypes = response.data.posts.reduce(
          (acc, item) => {
            // Translate post_type and content_type keys
            const translatedPostType = item.post_type
              ? t(`Backend.Post.PostType.${item.post_type}`)
              : null;
            const translatedContentType = item.content_type
              ? t(`Backend.Post.ContentType.${item.content_type}`)
              : null;

            // Count occurrences for translated post_type
            if (translatedPostType) {
              acc.post_type[translatedPostType] =
                (acc.post_type[translatedPostType] || 0) + 1;
            }

            // Count occurrences for translated content_type
            if (translatedContentType) {
              acc.content_type[translatedContentType] =
                (acc.content_type[translatedContentType] || 0) + 1;
            }

            return acc;
          },
          { post_type: {}, content_type: {} }
        );

        setTypes(countTypes);
      }
    };

    getData();
  }, []);

  return (
    <>
      {types && (
        <>
          <Col>
            <DonutChart
              chartLabels={Object.keys(types.post_type)}
              series={Object.values(types.post_type)}
              title={t("Backend.Dashboard.PostTypes")}
            />
          </Col>
          <Col>
            <DonutChart
              chartLabels={Object.keys(types.content_type)}
              series={Object.values(types.content_type)}
              title={t("Backend.Dashboard.ContentTypes")}
              type="pie"
            />
          </Col>
        </>
      )}
    </>
  );
};

export default PostTypes;
