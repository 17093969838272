import Poll from "../Entity/Poll";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import SocialEndpoints from "../endpoints";

const createPollForm = (
  setDataFunction,
) => {
  const validationSchema = Yup.object({
    Title: Yup.string().required("Required"),
  });

  const onSubmit = async (values) => {
    let payload = JSON.stringify(values, null, 2);
    const response = await CrudApi.edit(
      SocialEndpoints.posts.poll.edit,
      payload
    );
    if(response.ok){
      setDataFunction(response.data);
    }
  };

  return {
    initialValues: Poll,
    onSubmit: (values, actions) => {
      onSubmit(values);
    },
  };
};

const setEditData = (editData) => {
  let Poll = {
    id: editData.id,
    option1: editData.option1,
    option2: editData.option2,
    option3: editData.option3,
    option4: editData.option4,
    title: editData.title,
    duration: editData.duration    
  };

  return Poll;
};

export default { createPollForm, setEditData };
