import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";

const PollPreview = ({ poll }) => {
  const { t } = useTranslation();

  const optionStyle = {
    border: "1px solid #0a66c2",
    color: "#0a66c2",
    borderRadius: "1.8rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.2rem",
    fontWeight: "bold",
  };

  const getDurationText = (duration) => {
    switch (duration) {
      case "ONE_DAY":
        return t("Backend.Post.Poll.Runtime.OneDayLeft");
      case "THREE_DAYS":
        return t("Backend.Post.Poll.Runtime.ThreeDaysLeft");
      case "SEVEN_DAYS":
        return t("Backend.Post.Poll.Runtime.OneWeekLeft");
      case "FOURTEEN_DAYS":
        return t("Backend.Post.Poll.Runtime.TwoWeeksLeft");
      default:
        return t("Backend.Post.Poll.Runtime.ThreeDaysLeft"); // Handle other cases if needed
    }
  };

  return (
    poll && (
      <div
        style={{
          padding: "15px",
          border: "1px solid lightgray",
          borderRadius: "0.8rem",
        }}
      >
        <Row>
          <Col>
            <h3
              style={{ fontSize: "1.1rem", color: "black", fontWeight: "550" }}
            >
              {poll.title}
            </h3>
          </Col>
        </Row>
        {poll.option1 && (
          <Row className="mb-2">
            <Col style={optionStyle}>{poll.option1}</Col>
          </Row>
        )}
        {poll.option2 && (
          <Row className="mb-2">
            <Col style={optionStyle}>{poll.option2}</Col>
          </Row>
        )}
        {poll.option3 && (
          <Row className="mb-2">
            <Col style={optionStyle}>{poll.option3}</Col>
          </Row>
        )}
        {poll.option4 && (
          <Row className="mb-2">
            <Col style={optionStyle}>{poll.option4}</Col>
          </Row>
        )}
        <Row>
          <Col>{getDurationText(poll.duration)}</Col>
        </Row>
      </div>
    )
  );
};

export default PollPreview;
