import { Routes, Route } from "react-router-dom";
import Messages from "./Messages";
import Requests from "./Requests";
import MessageLog from "./MessageLog";

const OutreachRouter = () => {
  return (
    <Routes>
      <Route path="/messages" element={<Messages/>} />
      <Route path="/messagelog" element={<MessageLog/>} />
      <Route path="/requests/*" element={<Requests />} />
    </Routes>
  );
};

export default OutreachRouter;
