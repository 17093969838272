const UserConfig = {
  endpoints: {
    deleteAccount: "/account/requestdelete",
    confirmDeleteAccount: "/account/confirmdelete",
    cancelDelete: "/account/canceldelete",
    confirmCancelDelete: "/account/confirmcanceldelete",
    checkDeletion: "/account/goingtobedeleted",
    settings: {
      edit: "/usersettings/edit",
      get: "/usersettings/show",
      roles: "/usersettings/roles",
    },
  },
};

export default UserConfig;
