import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import crudApi from "@serie3/common/api/crudApi";
import DashboardEndpoints from "@serie3/mymediaplanner/Modules/Dashboard/endpoints";
import ListElement from "./ListElement";

const PlanOverview = () => {
  const [planData, setPlanData] = useState(null);
  useEffect(() => {
    const getData = async () => {
      const response = await crudApi.justGet(DashboardEndpoints.plans);
      if (response.ok) {
        setPlanData(response.data);
      }
    };

    getData();
  }, []);
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Body>
        <div>
          <ul className="list-group list-group-flush">
            {(planData && planData.nextPlanned && planData.nextPlanned[0])  && <ListElement title={t("Backend.Dashboard.NextPlanned")} data={planData.nextPlanned[0].post.title} icon="uil uil-calendar-alt"/>}
            {(planData && planData.lastPosted && planData.lastPosted[0])  && <ListElement title={t("Backend.Dashboard.LastPosted")} data={planData.lastPosted[0].post.title} icon="uil uil-history"/>}
            {(planData && planData.lastEdited && planData.lastEdited[0])  && <ListElement title={t("Backend.Dashboard.LastEdited")} data={planData.lastEdited[0].title} icon="uil uil-edit"/>}
          </ul>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PlanOverview;
