import { useTranslation } from "react-i18next";
import SelectField from "./SelectField";

const DistinctionTypeSelect = ({ formik }) => {
  const { t } = useTranslation();
  return (
    <SelectField
      formik={formik}
      label={t("Backend.Post.DistinctionType.Title")}
      valueName={"distinction"}
      style={{ marginBottom: "10px" }}
      value={formik.values.distincton}
    >
      <option value="Formal">{t("Backend.Post.DistinctionType.Formal")}</option>
      <option value="Informal">{t("Backend.Post.DistinctionType.Informal")}</option>
    </SelectField>
  );
};

export default DistinctionTypeSelect;
