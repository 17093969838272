import { Button } from "react-bootstrap";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
const AddButton = ({ addFunction }) => {
  return (
    <Button type="button" className="btn btn-primay" onClick={addFunction}>
      <FontAwesomeIcon icon={faAdd} />
    </Button>
  );
};

export default AddButton;
