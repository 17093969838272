import { Routes, Route } from "react-router-dom";
import AccountabilityHistory from "./AccountabilityHistory";
import Current from "./Current";



const AccountabilityRouter = ({props}) => {

  return (
    <Routes>
      <Route path="/current" element={<Current/>} />
      <Route path="/history" element={<AccountabilityHistory/>} />
    </Routes>
  );
};

export default AccountabilityRouter;
