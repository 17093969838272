import { create } from "apisauce";
import getSettings from "../config/settings";

const settings = getSettings();

const apiClient = create({
  baseURL: settings.apiUrl,
});

const baseURL = apiClient.getBaseURL();
apiClient.addAsyncRequestTransform(async (request) => {
  let authToken;
  let userId;
  const completeURL = `${baseURL}${request.url}`;
  if (typeof authStorage !== "undefined") {
    authToken = await authStorage.getToken();
  } else {
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    userId = JSON.parse(localStorage.getItem("localId"));
    authToken = userToken;
  }
  if (!authToken) return;
  request.headers["x-auth-token"] = authToken;
  request.headers["x-auth-id"] = userId;
});

apiClient.addResponseTransform((response) => {
  switch (response.status) {
    case 500:
      window.location.replace("/uuuups");
      break;
    case 403:
      if (window.location.hostname === "stage.mynomadplace.com") {
        window.location.replace("/datareset");
      }
      break;
    case 422:
      localStorage.removeItem("token");
      localStorage.removeItem("localId");
      window.location.replace("/requestemailvalidation");
      break;
    case 404:
      window.location.replace("/notfound");
      break;
  }
});

export default apiClient;
