import { Dropdown } from "react-bootstrap";
import EllipsisVerticalToggle from "./EllipsisVerticalToggle";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";

const CompleteDropdown = ({
  deleteAction = null,
  editAction = null,
  viewAction = null,
  children,
  addFront = false,
}) => {
  const { t } = useTranslation();

  return (
    <Dropdown style={{ float: "right", maxHeight: "16px", marginLeft: "5px" }}>
      <EllipsisVerticalToggle />
      <Dropdown.Menu>
        {addFront && children}
        {viewAction ? (
          <>
            <Dropdown.Item onClick={viewAction}>
              <FontAwesomeIcon icon={faEye} className="fs-6 me-2" />
              {t("Backend.Dropdown.View")}
            </Dropdown.Item>
            <div className="dropdown-divider" />
          </>
        ) : null}
        {editAction ? (
          <>
            <Dropdown.Item onClick={editAction}>
              <FontAwesomeIcon icon={faPen} className="fs-6 me-2" />
              {t("Backend.Dropdown.Edit")}
            </Dropdown.Item>
            <div className="dropdown-divider" />
          </>
        ) : null}
        {deleteAction ? (
          <>
            <Dropdown.Item onClick={deleteAction} className="text-danger">
              <FontAwesomeIcon icon={faTrash} className="fs-6 me-2" />
              {t("Backend.Dropdown.Delete")}
            </Dropdown.Item>
          </>
        ) : null}
        {!addFront && children}
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default CompleteDropdown;
