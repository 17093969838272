const linkeindAuthApiUrl = "https://www.linkedin.com/oauth/v2/authorization";

const linkedinSettings = {
  dev: {
    linkeindAuthApiUrl: linkeindAuthApiUrl,
    client_id: "86ognctf9wla0i",
    redirect_uri: "http://localhost:3000/callbacks/linkedin",
    scope: "r_basicprofile w_member_social profile openid email",
    response_type: "code",
  },
  stage: {
    linkeindAuthApiUrl: linkeindAuthApiUrl,
    client_id: "86ognctf9wla0i",
    redirect_uri: "https://stage.mymediaplanner.com/callbacks/linkedin",
    scope: "r_basicprofile w_member_social profile openid email",
    response_type: "code",
  },
  prod: {
    linkeindAuthApiUrl: linkeindAuthApiUrl,
    client_id: "86ognctf9wla0i",
    redirect_uri: "https://mymediaplanner.com/callbacks/linkedin",
    scope: "r_basicprofile w_member_social profile openid email",
    response_type: "code",
  },
};

const getLinkedinSettings = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "dev":
      return linkedinSettings.dev;
    case "stage":
      return linkedinSettings.stageing;
    case "stage":
      return linkedinSettings.stage;
    case "prod":
      return linkedinSettings.prod;
    default:
      return linkedinSettings.dev;
  }
};

export default getLinkedinSettings;
