import { useTranslation } from "react-i18next";

import SelectField from "./SelectField";

const Timezones = ({ formik }) => {
  const { t } = useTranslation();
  const timezones = t("timezones", { returnObjects: true });
  
  return (
    <SelectField
      formik={formik}
      label={t("Translation.Timezone")}
      valueName={"timezone"}
      style={{ marginBottom: "10px" }}
      value={formik.values.timezone}
    >
      {Object.keys(timezones).map((zone) => (
        <option key={zone} value={zone}>
          {timezones[zone]}
        </option>
      ))}
    </SelectField>
  );
};

export default Timezones;
