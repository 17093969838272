import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import crudApi from "@serie3/common/api/crudApi";
import DocumentsEndpoints from "@serie3/mymediaplanner/Modules/Documents/endpoints";

const PicturePreview = ({ file }) => {
  const { t } = useTranslation();
  const [picture, setPicture] = useState(null);
  const [video, setVideo] = useState(null);
  const [application, setApplication] = useState(null);

  useEffect(() => {
    const getImage = async () => {
      const response = await crudApi.getBinary(
        DocumentsEndpoints.show + file.document.id
      );
      
      if (response.status === 200) {
        const contentType = response.headers["content-type"];
        if (contentType.startsWith("image/")) {
          const url = window.URL.createObjectURL(response.data);
          setPicture(url); // Assuming setPicture is used for images
        } else if (contentType.startsWith("video/")) {
          const url = window.URL.createObjectURL(response.data);
          setVideo(url); // Assuming setVideo is used for videos
        } else if (contentType.startsWith("application/")) {
          const url = window.URL.createObjectURL(response.data);
          setApplication(url); // Assuming setVideo is used for videos
        }
        else {
          console.error("Unsupported media type:", contentType);
        }
      }
    };

    if (file.document && file.document.id) {
      getImage();
    }
  }, [file]);

  return (
    <div>
      {picture && (
        <img
          src={picture}
          alt="media content"
          style={{
            borderRadius: "1.6rem",
            maxWidth: "100%",
          }}
        />
      )}
      {video && (
        <video controls width="100%">
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      {application && (
        <embed src={application} type="application/pdf" width="100%" height="600px" />
      )}
    </div>
  );
};

export default PicturePreview;
