import Message from "../Entity/Message";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import MessagesEndpoints from "../endpoints";
import listHandler from "@serie3/common/Domain/listHandler";

const createMessageForm = (
  dataList,
  setDataFunction,
  setAdditionalData,
  saveVariant = false
) => {
  const validationSchema = Yup.object({
    Tipp: Yup.string().required("Required"),
  });

  const editFunction = async (values, originalValues) => {
    let payload = JSON.stringify(values, null, 2);
    const response = await CrudApi.edit(MessagesEndpoints.edit, payload);

    let originalPayload = JSON.stringify(originalValues, null, 2);
    listHandler.updateList(
      response,
      originalPayload,
      dataList,
      setDataFunction
    );
  };

  const onSubmit = async (values) => {
    const originalValues = { ...values };
    if (values.id > 0) {
      editFunction(values, originalValues);
    } else {
      let payload = JSON.stringify(values, null, 2);
      const response = await CrudApi.createNew(MessagesEndpoints.new, payload);

      listHandler.newToList(response, dataList, setDataFunction, null);

      if (response.ok) {
        values.id = response.data.id;
        let additionalData = {
          id: response.data.id,
        };
        setAdditionalData && setAdditionalData(additionalData);

        if (saveVariant) {
          const originalValues = { ...values };
          editFunction(values, originalValues);
        }
      }
    }
  };

  return {
    initialValues: Message,
    onSubmit: (values, actions) => {
      onSubmit(values);
    },
  };
};

const setEditData = (editData) => {
  let Message = {
    id: editData.id,
    title: editData.title,
    text: editData.text,
    active: editData.active,
  };

  return Message;
};

export default { createMessageForm, setEditData };
