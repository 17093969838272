import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const NewModal = ({
  show,
  setShow,
  children,
  ExtraButton = null,
  onShow = null,
  title = "",
  onHide = null,
  fullscreen = false,
  onExit = null,
  noHeader = false,
  noPadding= false,
  size='lg'
}) => {
  const { t } = useTranslation();

  const style = !noPadding ? null: {padding: "0px"};

  return (
    <Modal
      size={size}
      // @ts-ignore
      fullscreen={fullscreen}
      show={show}
      onHide={() => {
        setShow(false);
        typeof onHide === "function" && onHide();
      }}
      dialogClassName="modal-90w"
      onShow={() => {
        onShow && onShow();
      }}
      onExit={() => {
        onExit && onExit();
      }}
    >
      {!noHeader && (
        <Modal.Header closeButton={true}>
          <Modal.Title id="Billview">{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body style={style}>{children}</Modal.Body>
      <Modal.Footer>
        {ExtraButton ? <ExtraButton /> : null}
        <Button onClick={() => setShow(false)}>
          {t("Backend.Modal.Closebutton")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewModal;
