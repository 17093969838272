import { useTranslation } from "react-i18next";
import SelectField from "./SelectField";

const LanguageTypeSelect = ({ formik }) => {
  const { t } = useTranslation();
  return (
    <SelectField
      formik={formik}
      label={t("Translation.Title")}
      valueName={"language"}
      style={{ marginBottom: "10px" }}
      value={formik.values.language}
    >
      <option value="english">{t("Translation.English")}</option>
      <option value="german">{t("Translation.German")}</option>
    </SelectField>
  );
};

export default LanguageTypeSelect;
