import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";

const ListElement = ({ title, data, icon="uil uil-list-ul" }) => {
  const { t } = useTranslation();
  return (
    <li className="list-group-item">
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0 me-3">
          <div className="avatar-sm">
            <div className="avatar-title rounded-circle font-size-12">
            <i className={icon}></i>
            </div>
          </div>
        </div>
        <div className="flex-grow-1">
          <p className="text-muted mb-1">{title}</p>
          <h5 className="font-size-16 mb-0">
            {data ? data : "--"}
          </h5>
        </div>
      </div>
    </li>
  );
};

export default ListElement;
