import { Routes, Route } from "react-router-dom";
import Landingpage from "./Landingpage";
import Signup from "./Login/Signup";
import VerifyEmail from "./Login/VerifyEmail";
import Forgot from "./Login/Forgot";
import RequestValidationLink from "./Login/RequestValidationLink";
import Login from "./Login/Login";
import NotFoundPage from "../shared/404";
import HeaderNavbar from "../shared/HeaderNavbar";
import { useState } from "react";
import i18n from "../../i18n";


const FrontendRouter = ({ setToken }) => {
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  return (
    <>
      <HeaderNavbar
        currentLanguage={currentLanguage}
        setCurrentLanguage={setCurrentLanguage}
      />
      <Routes>
        <Route path="/signup" element={<Signup />} />
        <Route path="/verifyemail" element={<VerifyEmail />} />
        <Route path="/resetpassword" element={<Forgot />} />
        <Route path="/login" element={<Login setToken={setToken} />} />
        <Route
          path="/requestemailvalidation"
          element={<RequestValidationLink />}
        />
        <Route path="/" element={<Login setToken={setToken} />} />
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default FrontendRouter;
